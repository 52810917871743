<!--
 * @FilePath: OrderMessage.vue
 * @Descripttion: 订单消息组件
 * @version: 1.0.0
 * @Author: 李金深
 * @Date: 2023-01-31 18:56:15
 * @LastEditors: 李金深 2896583081@qq.com
 * @LastEditTime: 2023-02-10 18:30:57
-->
<template>
  <div class="flex-1">
    <div class="flex w-full" :class="isMine ? 'justify-end' : 'justify-start'">
      <div class="flex items-center p-2 message_box" :class="isMine ? 'is_mine' : ''">
        <div :style="{ maxWidth: isMobile ? '100%' : '25rem' }" class="p-3 duration-500 bg-white rounded">
          <div class="flex text-black">
            <div style="width:2.5rem;height:2.5rem;overflow: hidden;" class="rounded-md">
              <img :src="message.fromUser.avatar" alt="" style="width:100%;height:100%;object-fit:cover" />
            </div>
            <div class="flex-1 pl-2">
              <div class="text-left">ID：{{ message.fromUser.account }}</div>
              <div class="flex items-center justify-between w-full">
                <div class="text-left">
                  手机号：{{ message.fromUser.mobile }}
                </div>
                <div class="text-xs text-white border border-solid rounded cursor-pointer bg-primary border-primary"
                  style="padding: 0.125rem .625rem" @click="viewDetail">
                  详情
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 text-black">
            <table class="w-full" style="text-align: left;">
              <tr>
                <td style="width:7.5rem;text-align: left;padding: .625rem .625rem;">
                  订单编号
                </td>
                <td style="flex:1;text-align: left;padding: .625rem .625rem;word-break: break-all;">
                  {{ orderInfo.serviceOrderNumber }}
                </td>
              </tr>
              <tr>
                <td style="width:7.5rem;text-align: left;padding: .625rem .625rem;">
                  型号
                </td>
                <td style="flex:1;text-align: left;padding: .625rem .625rem;">
                  {{ orderInfo.modelName }}
                </td>
              </tr>
              <tr>
                <td style="width:7.5rem;text-align: left;padding: .625rem .625rem;">
                  提交时间
                </td>
                <td style="flex:1;text-align: left;padding: .625rem .625rem;">
                  {{ orderInfo.createTime }}
                </td>
              </tr>
              <tr>
                <td style="width:7.5rem;text-align: left;padding: .625rem .625rem;">
                  状态
                </td>
                <td style="flex:1;text-align: left;padding: .625rem .625rem;">
                  {{ orderStatusMap[orderInfo.status] }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="flex mt-2" :class="isMine ? 'justify-end' : 'justify-start'">
      <div class="flex items-center">
        <div class="text-sm cursor-pointer md:text-sm text-primary" v-if="isMine && isCan" @click="retractionMessage">
          撤回
        </div>
        <div class="text-sm text-gray-500" :class="isMine ? 'pl-3' : 'pr-3'">
          {{ transformDate(message.sendTime, "full", "-") }}
        </div>
      </div>
    </div>
    <el-drawer title="工单详情" :visible.sync="drawer" direction="btt" size="80vh" :before-close="handleClose">
      <div style="width: 98vw;height: 100%;overflow-y: scroll;" class="px-3 pb-2">
        <order-table :orderInfo="orderInfo"></order-table>
      </div>
    </el-drawer>
    <Popup :isShow="isShow" @close="closeFunc">
      <template>
        <div class="p-4" style="max-height:80vh;overflow-y: scroll;">
          <order-table :orderInfo="orderInfo"></order-table>
        </div>
      </template>
    </Popup>
  </div>
</template>
<script>
import { mapState } from "vuex";
import mixinFunc from "./mixin";
import { orderStatusMap } from "@/utils/utils.js";
import Popup from "@/components/Popup/Popup";
import OrderTable from "../OrderTable.vue";
export default {
  mixins: [mixinFunc],
  components: {
    Popup,
    OrderTable,
  },
  props: {
    isMine: {
      type: Boolean,
      default: false,
    },
    message: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      orderInfo: {}, // 工单详情
      orderStatusMap,
      drawer: false,
      isShow: false,
    };
  },
  computed: {
    ...mapState(["isMobile"]),
  },
  created() {
    if (this.message.content) {
      this.getInfo();
    }
    this.retractionMessageDeal(this.message);
  },
  methods: {
    /**
     * 查看工单详情
     */
    viewDetail() {
      if (this.isMobile) {
        this.isShow ? (this.isShow = false) : "";
        this.drawer = true;
      } else {
        this.drawer ? (this.drawer = false) : "";
        this.isShow = true;
      }
    },

    /**
     * 关闭移动端弹窗
     */
    handleClose() {
      this.drawer = false;
    },
    /**
     * 关闭web端弹窗
     */
    closeFunc() {
      this.isShow = false;
    },
    /**
     * 消息撤回
     */
    retractionMessage() {
      this.$store.dispatch("chat/messageRetraction", this.message.id);
    },
    /**
     * 获取维修详情
     */
    getInfo() {
      this.$request({
        url: this.$requestPath.serveEquipmentDetail,
        methods: "get",
        params: {
          warrantyId: this.message.content,
        },
      }).then((res) => {
        // console.log("获取的订单详情", res);
        if (res.code == 0) {
          let data = res.data;
          if (data.custom) {
            data.custom = JSON.parse(data.custom);
            Object.keys(data.custom).forEach((key) => {
              data[key] = data.custom[key];
            });
            this.orderInfo = Object.assign({}, data);
          }
        }
      });
      // this.$store.dispatch("order/getRepairInfo", {
      //   warrantyId: this.message.content,
      //   callback: (data) => {
      //     if (data.custom) {
      //       data.custom = JSON.parse(data.custom);
      //       Object.keys(data.custom).forEach((key) => {
      //         data[key] = data.custom[key];
      //       });
      //       this.orderInfo = Object.assign({}, data);
      //     }
      //   },
      // });
    },
  },
};
</script>
<style lang="scss" scoped>
@import url("./common.scss");

table,
td,
th {
  text-align: center;
  border: 1px solid #000;
  border-collapse: collapse;
}
</style>
