<!--
 * @FilePath: VideoMessage.vue
 * @Descripttion: 视频消息组件
 * @version: 1.0.0
 * @Author: 李金深
 * @Date: 2023-01-18 14:09:02
 * @LastEditors: 李金深
 * @LastEditTime: 2023-02-01 11:05:37
-->
<template>
  <div class="flex-1">
    <div class="flex w-full" :class="isMine ? 'justify-end' : 'justify-start'">
      <div
        class="flex items-center p-2 message_box"
        :class="isMine ? 'is_mine' : ''"
      >
        <video
          :src="message.content"
          controls
          :style="{ maxWidth: isMobile ? '100%' : '25rem' }"
        ></video>
      </div>
    </div>
    <div class="flex mt-2" :class="isMine ? 'justify-end' : 'justify-start'">
      <div class="flex items-center">
        <div
          class="text-sm cursor-pointer md:text-sm text-primary"
          v-if="isMine && isCan"
          @click="retractionMessage"
        >
          撤回
        </div>
        <div class="text-sm text-gray-500" :class="isMine ? 'pl-3' : 'pr-3'">
          {{ transformDate(message.sendTime, "full", "-") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import mixinFunc from "./mixin";
export default {
  mixins: [mixinFunc],
  props: {
    isMine: {
      type: Boolean,
      default: false,
    },
    message: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["isMobile"]),
  },
  created() {
    this.retractionMessageDeal(this.message);
  },
  methods: {
    retractionMessage() {
      this.$store.dispatch("chat/messageRetraction", this.message.id);
    },
  },
};
</script>
<style lang="scss" scoped>
@import url("./common.scss");
</style>
