/*
 * @FilePath: mixin.js
 * @Descripttion: 混入的文件：关于对撤回按钮的处理
 * @version: 1.0.0
 * @Author: 李金深
 * @Date: 2023-01-18 14:20:32
 * @LastEditors: 李金深
 * @LastEditTime: 2023-02-01 11:08:22
 */
export default {
  data() {
    return {
      isCan: false, // 是否可以撤回
      timer: null, // 定时器
    };
  },
  methods: { 
    /**
     * @Descripttion: 使用定时器对撤回按钮处理（超过5分钟不再显示撤回按钮）
     * @param {*} message message 渲染的消息
     * @return {*}
     */    
    retractionMessageDeal(message) {
      clearInterval(this.timer);
      let distanceTime = (new Date().getTime() - message.sendTime) / 1000 / 60;
      if (distanceTime > 5) {
        this.isCan = false;
      } else {
        this.isCan = true;
        this.timer = setInterval(() => {
          let distanceTime =
            (new Date().getTime() - message.sendTime) / 1000 / 60;
          if (distanceTime > 5) {
            this.isCan = false;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
  },
};
