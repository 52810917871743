<!--
 * @FilePath: OrderInfo.vue
 * @Descripttion: 订单详情组件
 * @version: 1.0.0
 * @Author: 李金深
 * @Date: 2023-01-18 15:08:39
 * @LastEditors: 李金深 2896583081@qq.com
 * @LastEditTime: 2023-02-10 18:30:25
-->
<template>
  <div class="h-full" style="overflow-y: scroll;">
    <div>
      <div class="flex items-center">
        <div style="overflow: hidden;" class="rounded-md" :style="{
          width: isMobile ? '2.1875rem' : '2.8125rem',
          height: isMobile ? '2.1875rem' : '2.8125rem',
        }">
          <img :src="fromUser.avatar" alt="" style="width:100%;height:100%;object-fit:cover" />
        </div>
        <div class="pl-2">
          <div class="text-left">ID：{{ fromUser.account }}</div>
          <div class="text-left">手机号：{{ fromUser.mobile }}</div>
        </div>
      </div>
    </div>
    <template v-if="orderList.length > 0">
      <div v-for="(item, index) in orderList" :key="index">
        <div class="mt-4 mb-2">
          <table class="w-full" style="text-align: left;">
            <tr>
              <td style="width:7.5rem;text-align: left;padding: .625rem .625rem;">
                订单编号
              </td>
              <td style="flex:1;text-align: left;padding: .625rem .625rem;">
                {{ item.serviceOrderNumber }}
              </td>
            </tr>
            <tr>
              <td style="width:7.5rem;text-align: left;padding: .625rem .625rem;">
                型号
              </td>
              <td style="flex:1;text-align: left;padding: .625rem .625rem;">
                {{ item.modelName }}
              </td>
            </tr>
            <tr>
              <td style="width:7.5rem;text-align: left;padding: .625rem .625rem;">
                提交时间
              </td>
              <td style="flex:1;text-align: left;padding: .625rem .625rem;">
                {{ item.createTime }}
              </td>
            </tr>
            <tr>
              <td style="width:7.5rem;text-align: left;padding: .625rem .625rem;">
                状态
              </td>
              <td style="flex:1;text-align: left;padding: .625rem .625rem;">
                {{ orderStatusMap[item.status] }}
              </td>
            </tr>
          </table>
        </div>
        <div class="flex py-3" :class="userInfo.isService ? 'justify-end' : 'justify-center'">
          <div style="height: 2.5rem;" :style="{ width: userInfo.isService ? '30%' : '100%' }"
            class="flex items-center justify-center text-white rounded cursor-pointer bg-primary"
            @click="sendOrder(item)">
            {{ userInfo.isService ? "发送" : "发给客服" }}
          </div>
        </div>
      </div>
    </template>
    <div v-else class="my-4 text-xs">
      暂无工单
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { orderStatusMap, generateRandId } from "@/utils/utils.js";
export default {
  props: {
    orderList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    fromUser: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      orderStatusMap,
    };
  },
  computed: {
    ...mapState(["isMobile", "userInfo"]),
    ...mapState("chat", ["currentSessionUser", "imUserId"]),
  },
  methods: {
    // 发送工单
    sendOrder(item) {
      // 消息发送
      let data = {
        id: generateRandId(),
        type: "order",
        sendTime: new Date().getTime(),
        content: item.warrantyId,
        toContactId: this.currentSessionUser.user_id,
        is_group: 0,
        fromUser: {
          //如果 id == this.user.id消息会显示在右侧，否则在左侧
          id: this.imUserId,
          displayName: this.userInfo.userName,
          avatar: this.userInfo.userPicture,
          user_id: this.imUserId,
        },
      };
      this.$request({
        url: this.$requestPath.sendMessageAPI,
        method: "POST",
        data,
      }).then((res) => {
        if (res.code == 0) {
          this.$store.commit("chat/messageUpperWindow", data);
          this.$emit("closeFunc")
          window.$EventBus.$emit("toast", {
            type: "success",
            message: '发送成功',
          });
          this.$EventBus.$emit("scrollToButtom");
        } else {
          window.$EventBus.$emit("toast", {
            type: "error",
            message: res.msg,
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
table,
td,
th {
  text-align: center;
  border: 1px solid #000;
  border-collapse: collapse;
}
</style>
