<!--
 * @FilePath: OrderTable.vue
 * @Descripttion: 订单表格组件
 * @version: 1.0.0
 * @Author: 李金深
 * @Date: 2023-01-18 15:49:33
 * @LastEditors: 李金深 2896583081@qq.com
 * @LastEditTime: 2023-03-28 09:31:01
-->
<template>
  <div>
    <table class="w-full" style="text-align: left;">
      <tr v-for="item in orderFiledList" :key="item.id">
        <td class="left_td" style="text-align: left;padding: .625rem .625rem;">
          {{ item.name }}
        </td>
        <td style="flex:1;text-align: left;padding: .625rem .625rem;">
          <div v-if="item.type != 2">
            {{
              item.fieldName == "modelId"
              ? orderInfo.modelName
              : orderInfo[item.fieldName]
            }}
          </div>
          <div class="flex flex-wrap" v-else>
            <div style="width:100px;height: 100px;" v-for="imgUrl in orderInfo[item.fieldName]" :key="imgUrl">
              <img :src="imgUrl" alt="" style="width: 95%;height: 95%;" @click="review(imgUrl)" class="cursor-pointer" />
            </div>
          </div>
        </td>
      </tr>
    </table>

    <table class="w-full mt-8" style="text-align: left;">
      <tr>
        <td class="left_td" style="text-align: left;padding: .625rem .625rem;">
          状态
        </td>
        <td style="flex:1;text-align: left;padding: .625rem .625rem;">
          <div class="flex items-center justify-between w-full">
            <span> {{ orderStatusMap[orderInfo.status] }}</span>
            <span class="pt-2 cursor-pointer text-primary" @click="goLogistics('merchant')" v-if="orderInfo.status > 5">
              查看物流
            </span>
          </div>
        </td>
      </tr>
      <tr>
        <td class="left_td" style="text-align: left;padding: .625rem .625rem;">
          工单号
        </td>
        <td style="flex:1;text-align: left;padding: .625rem .625rem;">
          {{ orderInfo.serviceOrderNumber }}
        </td>
      </tr>
      <tr v-if="orderInfo.status == 4">
        <td class="left_td" style="text-align: left;padding: .625rem .625rem;">
          物流公司
        </td>
        <td style="flex:1;text-align: left;padding: .625rem .625rem;">
          {{ orderInfo.company }}
        </td>
      </tr>
      <tr v-if="orderInfo.status > 3">
        <td class="left_td" style="text-align: left;padding: .625rem .625rem;">
          收货人信息
        </td>
        <td style="flex:1;text-align: left;padding: .625rem .625rem;" v-html="orderInfo.platformReceiving"></td>
      </tr>
      <tr v-if="orderInfo.status == 4">
        <td class="left_td" style="text-align: left;padding: .625rem .625rem;">
          我的物流信息
        </td>
        <td style="flex:1;text-align: left;padding: .625rem .625rem;">
          <div>
            <span>物流公司：{{ orderInfo.company }}</span>
            <span class="ml-4">物流单号：{{ orderInfo.shipmentNumber }}</span>
          </div>
          <div class="py-1">
            <span>收货人名称：{{ orderInfo.contactPerson }}</span>
            <span class="ml-4">收货人电话：{{ orderInfo.phone }}</span>
          </div>
          <div>收货地址：{{ orderInfo.address }}</div>
          <div class="pt-2 cursor-pointer text-primary" @click="goLogistics('user')">
            查看物流
          </div>
        </td>
      </tr>
      <tr>
        <td class="left_td" style="text-align: left;padding: .625rem .625rem;">
          维修详情
        </td>
        <td style="flex:1;text-align: left;padding: .625rem .625rem;">
          {{ orderInfo.remark }}
        </td>
      </tr>
    </table>
    <Popup :isShow="isShow" @close="closeSearch" :width="isMobile ? '90%' : '70%'">
      <template>
        <div class="flex justify-center">
          <img :src="reviewImg" alt />
        </div>
      </template>
    </Popup>
  </div>
</template>
<script>
import Popup from "@/components/Popup/Popup";
import { orderStatusMap } from "@/utils/utils.js";
import { mapState } from "vuex";
export default {
  props: {
    orderInfo: {
      type: Object,
      default: () => { },
    },
  },
  components: {
    Popup,
  },
  data() {
    return {
      orderStatusMap,
      isShow: false,
      reviewImg: "",
    };
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapState("order", ["orderFiledList"]),
  },
  methods: {
    // 图片预览
    review(url) {
      this.isShow = true;
      this.reviewImg = url;
    },
    // 关闭弹窗
    closeSearch() {
      this.isShow = false;
    },
    // 跳转到物流页面
    goLogistics(type) {
      localStorage.setItem("ORDER_INFO", JSON.stringify(this.orderInfo));
      this.$router.push({
        path: "/index/sales/orderLogistics?type=" + type,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
table,
td,
th {
  text-align: center;
  border: 1px solid #000;
  border-collapse: collapse;
}

@media screen and (max-width: 640px) {
  .path {
    font-size: 0.875rem;
  }

  .left_td {
    width: 6.25rem;
  }
}

@media screen and (min-width: 640px) {
  .img_box {
    max-width: 500px;
  }

  .path {
    font-size: 1rem;
  }

  .left_td {
    width: 15.625rem;
  }
}

.tabitem-enter-active,
.tabitem-leave-active {
  transition: all 0.6s ease;
}

.tabitem-enter,
.tabitem-leave-to {
  opacity: 0;
  transform: translateX(0.625rem);
}

.result-enter-active,
.result-leave-active {
  transition: all 0.6s ease;
}

.result-enter,
.result-leave-to {
  opacity: 0;
  transform: translateX(0.625rem);
}
</style>
