<!--
 * @Author: 李金深 2896583081@qq.com
 * @Date: 2023-02-06 09:07:28
 * @LastEditors: 李金深 2896583081@qq.com
 * @LastEditTime: 2023-03-17 17:36:35
 * @FilePath: /com.huizhouyiren.b0805/src/views/index/pages/sales/components/MessageInput.vue
 * @Description: 消息输入组件
-->
<template>
  <div class="bg-gray-100" style="width:100%;padding: 10px;" id="div-textarea">
    <textarea v-model="text" style="width: 100%;resize:none;" :style="{ height: inputHeight - 60 + 'px' }"
      class="bg-gray-100" placeholder="小提示：点击键盘Enter键可以快速发送输入的文字" @keyup.enter="sendTextMessage" ref="textarea"
      @focus="focusFunc"></textarea>
    <div class="flex items-center justify-between" style="height:40px">
      <div class="flex items-center">
        <div @click="slectImage">
          <input type="file" accept="image/*" id="imageInput" style="display: none" @change="sendImage" />
          <img src="../../../../../assets/images/chat/chat1.png" alt=""
            :style="{ width: isMobile ? '1.5rem' : '1.875rem' }" class="cursor-pointer" />
        </div>
        <div @click="slectVideo">
          <input type="file" accept="video/*" id="videoInput" style="display: none" @change="sendVideo" />
          <img src="../../../../../assets/images/chat/chat2.png" class="ml-4 cursor-pointer" alt=""
            :style="{ width: isMobile ? '1.5rem' : '1.875rem' }" />
        </div>
        <div v-if="showPercent" class="ml-4">上传进度：{{ percent + "%" }}</div>
      </div>
      <div class="flex items-center">
        <div
          class="relative flex items-center justify-center mr-2 border border-solid rounded cursor-pointer text-primary border-primary"
          style="width:6.25rem;height: 1.875rem;" v-if="userInfo.isService">
          <div class="flex items-center cursor-pointer" style="height: 100%;height: 100%;user-select: none;"
            @click="showQuickFunc">
            快捷回复
          </div>
          <div v-show="showQuick" class="absolute px-3 text-black bg-white rounded cursor-pointer"
            style="height: 12.5rem;width: 18.75rem;bottom: 1.875rem;right: 0;overflow-y: scroll;box-shadow: 0px 0px 10px 10px rgba(0,0,0,.05);">
            <div v-for="item in quickReply" :key="item.quickId"
              class="py-3 text-left border-b border-gray-200 border-solid" style="word-wrap: break-word"
              @click="sendQuickMessage(item)">
              {{ item.message }}
            </div>
          </div>
        </div>
        <div class="flex items-center justify-center text-white rounded cursor-pointer bg-primary"
          style="width:6.25rem;height: 1.875rem;" @click.prevent="sendTextMessage">
          发送
        </div>
      </div>
    </div>
    <el-drawer title="快捷回复" :visible.sync="drawer" direction="btt" size="50vh">
      <div style="height:100%;overflow-y: scroll;">
        <div v-for="item in quickReply" :key="item.quickId" class="px-2 py-3" @click="sendQuickMessage(item)">
          {{ item.message }}
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { generateRandId } from "@/utils/utils.js";
// eslint-disable-next-line no-undef
let qiniu = require("@/utils/qiniu.min.js");
export default {
  props: {
    inputHeight: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      showQuick: false, // 快捷回复窗口的显示标识 web端
      drawer: false, // 快捷回复窗口的显示标识 移动端
      text: "", // 输入的信息
      showPercent: false, // 显示上传进度
      percent: "10",
    };
  },
  created() {

  },
  mounted() {
    // this.inputFocus();
    this.listenerPaste()
  },
  computed: {
    ...mapState(["isMobile", "qiniuToken", "cdnUrl", "userInfo"]),
    ...mapState("chat", ["quickReply", "currentSessionUser", "imUserId"]),
  },
  methods: {
    /**
     * @description: 监听粘贴图片事件
     * @return {*}
     */
    listenerPaste() {
      document.getElementById("div-textarea")
        .addEventListener("paste", (event) => {
          var items = event.clipboardData.items;
          if (items && items[0].type.indexOf("image") > -1) {
            var file = items[0].getAsFile();
            this.qiniuUplod(file, "image")
          }
        });
    },
    /**
     * @description: 自动获取输入框焦点
     * @return {*}
     */
    inputFocus() {
      this.$nextTick(() => {
        this.$refs.textarea.focus();
      });
    },
    /**
     * @description: 文本框获取到焦点的事件
     * @return {*}
     */
    focusFunc() {
      // this.$nextTick(() => {
      //   this.$EventBus("scrollToButtom")
      // });
    },
    // 显示快捷回复
    showQuickFunc() {
      this.isMobile
        ? (this.drawer = !this.drawer)
        : (this.showQuick = !this.showQuick);
    },
    // 发送快捷消息
    sendQuickMessage(item) {
      if (!this.currentSessionUser.user_id) {
        this.$EventBus.$emit("toast", { type: "error", message: "暂无联系人" });
        return;
      }
      this.sendMessage({ content: item.message, type: "text" });
      this.showQuickFunc();
    },
    sendTextMessage() {
      this.inputFocus()
      if (!this.currentSessionUser.user_id) {
        this.$EventBus.$emit("toast", { type: "error", message: "暂无联系人" });
        return;
      }
      if (this.isNull(this.text)) {
        this.$EventBus.$emit("toast", { type: "error", message: "请输入文字" });
        return;
      }
      this.sendMessage({ content: this.text, type: "text" });
    },
    // 消息发送
    sendMessage({ content, type }) {
      let data = {
        id: generateRandId(),
        type,
        sendTime: new Date().getTime(),
        content,
        toContactId: this.currentSessionUser.user_id,
        is_group: 0,
        fromUser: {
          //如果 id == this.user.id消息会显示在右侧，否则在左侧
          id: this.imUserId,
          displayName: this.userInfo.userName,
          avatar: this.userInfo.userPicture,
          user_id: this.imUserId,
        },
      };
      this.$request({
        url: this.$requestPath.sendMessageAPI,
        method: "POST",
        data,
      }).then((res) => {
        if (res.code == 0) {
          // this.inputFocus();
          this.text = "";
          this.$store.commit("chat/messageUpperWindow", data);
          this.$EventBus.$emit("scrollToButtom");
        } else {
          window.$EventBus.$emit("toast", {
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    slectImage() {
      if (!this.currentSessionUser.user_id) {
        this.$EventBus.$emit("toast", { type: "error", message: "暂无联系人" });
        return;
      }
      document.getElementById("imageInput").click();
    },
    slectVideo() {
      if (!this.currentSessionUser.user_id) {
        this.$EventBus.$emit("toast", { type: "error", message: "暂无联系人" });
        return;
      }
      document.getElementById("videoInput").click();
    },
    // 发送视频信息
    sendVideo() {
      this.uploadFile("videoInput", "video");
    },
    sendImage() {
      this.uploadFile("imageInput", "image");
    },
    // 文件上传
    uploadFile(eleId, msgType, type = 'select') {
      this.showPercent = true;
      var filePicker = document.getElementById(eleId);
      let file = filePicker.files[0];
      // let size = (file.size / 1024 / 1024).toFixed(2);
      // let name = file.name;
      // let type = this.fileType(file.name);
      // if (size > maxSize) {
      //   this.$message({
      //     type: "error",
      //     message: "文件大小不能超过5M",
      //   });
      //   return;
      // }
      // console.log(file, name, this.qiniuToken);
      this.qiniuUplod(file, msgType)
    },
    qiniuUplod(file, msgType) {
      let name = new Date().getTime() + "";
      let observable = qiniu.upload(file, name, this.qiniuToken);
      observable.subscribe({
        // 上传开始
        next: (result) => {
          // 接收上传进度信息，result是带有total字段的 Object
          // loaded: 已上传大小; size: 上传总信息; percent: 当前上传进度
          // console.log(result); // 形如：{total: {loaded: 1671168, size: 2249260, percent: 74.29856930723882}}

          this.percent = result.total.percent.toFixed(0);
          if (this.percent < 100) {
            this.showPercent = true;
          } else {
            this.showPercent = false;
          }
        },
        error: (errResult) => {
          this.$message({
            type: "error",
            // message: "文件上传失败，请刷新页面后重新上传",
            message: JSON.stringify(errResult)
          });
          // 上传错误后失败报错
          console.log(errResult);
          this.showPercent = false;
        },
        complete: (result) => {
          result.url = this.cdnUrl + "/" + result.key;

          // result.type = type;
          // result.name = name;

          this.sendMessage({ content: result.url, type: msgType });

          // 接收成功后返回的信息
          // console.log(result); // 形如：{hash: "Fp5_DtYW4gHiPEBiXIjVsZ1TtmPc", key: "%TStC006TEyVY5lLIBt7Eg.jpg"}
          // this.sendCustomMessage({
          //   data: "file", // 消息标识
          //   description: "文件信息",
          //   extension: JSON.stringify(result),
          // });
        },
      });
    }
  },
};
</script>
<style lang="scss" scoped></style>
