<!--
 * @FilePath: TextMessage.vue
 * @Descripttion: 文本消息组件
 * @version: 1.0.0
 * @Author: 李金深
 * @Date: 2023-01-18 14:06:54
 * @LastEditors: 李金深 2896583081@qq.com
 * @LastEditTime: 2023-03-29 10:56:32
-->
<template>
  <div class="flex-1">
    <div class="flex items-center w-full" :class="isMine ? 'justify-end' : 'justify-start'">
      <div class="flex items-center p-2 message_box" :class="isMine ? 'is_mine' : ''">
        <div style="word-break: break-all;flex:1;text-decoration: underline;color: green;" class="cursor-pointer "
          v-if="message.content.indexOf('https://') >= 0 || message.content.indexOf('http://') >= 0"
          @click="openUrl(message.content)">{{ message.content }}
        </div>
        <div style="word-break: break-all;flex:1" v-else>{{ message.content }}</div>
      </div>
    </div>
    <div class="flex mt-2" :class="isMine ? 'justify-end' : 'justify-start'">
      <div class="flex items-center">
        <div class="text-sm cursor-pointer md:text-sm text-primary" v-if="isMine && isCan" @click="retractionMessage">
          撤回
        </div>
        <div class="text-sm text-gray-500" :class="isMine ? 'pl-3' : 'pr-3'">
          {{ transformDate(message.sendTime, "full", "-") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import mixinFunc from "./mixin";
export default {
  mixins: [mixinFunc],
  props: {
    isMine: {
      type: Boolean,
      default: false,
    },
    message: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["isMobile"]),
  },
  created() {
    this.retractionMessageDeal(this.message);
  },
  methods: {
    retractionMessage() {
      this.$store.dispatch("chat/messageRetraction", this.message.id);
    },
    openUrl(url) {
      window.open(url)
    }
  },
};
</script>
<style lang="scss" scoped>
@import url("./common.scss");
</style>
