<!--
 * @FilePath: SystemMessage.vue
 * @Descripttion: 系统消息组件
 * @version: 1.0.0
 * @Author: 李金深
 * @Date: 2023-01-16 09:55:55
 * @LastEditors: 李金深
 * @LastEditTime: 2023-02-01 11:04:57
-->
<template>
  <div class="py-3 text-gray-500">
    {{ message.content }}
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    message: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    ...mapState("chat", ["imUserId"]),
  },
};
</script>
<style lang="scss" scoped></style>
