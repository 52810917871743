var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-4 equipment_box"},[_c('navbar-text',{attrs:{"navbarList":_vm.navbarList}}),_c('div',{staticClass:"flex justify-between",style:({ height: _vm.chatHeight + 'px' })},[(_vm.isServer && !_vm.isMobile)?_c('div',{staticClass:"h-full bg-gray-100",staticStyle:{"width":"21.875rem","overflow-y":"scroll"}},[_c('chat-list',{on:{"viewOrder":_vm.viewOrder}})],1):_vm._e(),_c('div',{staticClass:"flex-1 chat_box"},[(_vm.isMobile)?_c('div',{staticClass:"flex items-center justify-between px-3 bg-gray-100",staticStyle:{"overflow":"hidden"},style:({ height: _vm.headerHeight + 'px' })},[(_vm.userInfo.isService)?[_c('div',[_c('div',{staticClass:"text-left"},[_vm._v("ID："+_vm._s(_vm.currentSessionUser.account))]),_c('div',{staticClass:"text-left"},[_vm._v(" 手机号："+_vm._s(_vm.currentSessionUser.mobile)+" ")])])]:[_vm._m(0)],_c('div',{staticClass:"flex items-center"},[(!_vm.isServer)?_c('div',[_c('span',{staticClass:"text-sm text-primary",on:{"click":function($event){_vm.orderDrawer = true}}},[_vm._v("工单列表")])]):_vm._e(),(_vm.userInfo.isService)?_c('div',{staticClass:"flex items-center",staticStyle:{"margin-left":".625rem"},on:{"click":function($event){_vm.drawer = true}}},[(_vm.unreadFlag)?_c('div',{staticClass:"text-xs text-white rounded bg-primary",staticStyle:{"top":"-10px","right":"-10px","padding":"2px 6px"}},[_vm._v(" news ")]):_vm._e(),_c('img',{staticStyle:{"width":"1.625rem"},attrs:{"src":require("../../../../assets/images/chat/chat.png"),"alt":""}})]):_vm._e()])],2):_vm._e(),_c('message-list',{attrs:{"messageHeight":_vm.messageHeight}}),_c('message-input',{attrs:{"inputHeight":_vm.inputHeight}})],1),(!_vm.isServer && !_vm.isMobile)?_c('div',{staticClass:"p-4 bg-gray-100",staticStyle:{"width":"21.875rem"}},[_c('order-info',{attrs:{"orderList":_vm.orderList,"fromUser":{
        avatar: _vm.userInfo.userPicture,
        account: _vm.userInfo.userId,
        mobile: _vm.userInfo.telphone,
      }}})],1):_vm._e()]),_c('el-drawer',{attrs:{"title":"聊天列表","visible":_vm.drawer,"direction":_vm.direction,"before-close":_vm.handleClose,"size":"85vw"},on:{"update:visible":function($event){_vm.drawer=$event}}},[_c('div',{staticClass:"px-3 pb-2",staticStyle:{"width":"85vw","height":"100%","overflow-y":"scroll"}},[_c('chat-list',{on:{"viewOrder":_vm.viewOrder}})],1)]),_c('el-drawer',{attrs:{"title":"工单列表","visible":_vm.orderDrawer,"direction":"btt","before-close":_vm.handleOrderClose,"size":"90vh"},on:{"update:visible":function($event){_vm.orderDrawer=$event}}},[_c('div',{staticClass:"px-3 pb-2",staticStyle:{"width":"98vw","height":"100%","overflow-y":"scroll"}},[_c('order-info',{attrs:{"orderList":_vm.orderList,"fromUser":{
        avatar: _vm.userInfo.userPicture,
        account: _vm.userInfo.userId,
        mobile: _vm.userInfo.telphone,
      }},on:{"closeFunc":_vm.emitClose}})],1)]),_c('Popup',{attrs:{"isShow":_vm.isShow},on:{"close":_vm.closeFunc}},[[_c('div',{staticClass:"p-4",staticStyle:{"max-height":"80vh","overflow-y":"scroll"}},[_c('order-info',{attrs:{"orderList":_vm.orderList,"fromUser":_vm.fromUser},on:{"closeFunc":_vm.emitClose}})],1)]],2)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"font-bold text-left"},[_vm._v("客服")])])}]

export { render, staticRenderFns }