<!--
 * @FilePath: MessageList.vue
 * @Descripttion: 消息列表渲染组件
 * @version: 1.0.0
 * @Author: 李金深
 * @Date: 2023-01-16 10:39:26
 * @LastEditors: 李金深 2896583081@qq.com
 * @LastEditTime: 2023-02-15 18:17:21
-->
<template>
  <div :style="{ height: messageHeight + 'px' }" styl="overflow:hidden">
    <div class="px-2" style="overflow-y: auto;height:100%;" ref="messageEle" id="messageEle">
      <div class="py-2 text-xs cursor-pointer md:text-sm" v-if="message.maxPage > message.page" @click="loadmore">
        点击查看更多...
      </div>
      <div v-else class="py-2 text-xs md:text-sm">没有更多了</div>
      <div>
        <div class="w-full " :class="
          item.fromUser.user_id != imUserId ? 'justify-start' : 'justify-end'
        " v-for="item in message.list" :key="item.id">
          <template v-if="item.type == 'event'">
            <system-message :message="item"></system-message>
          </template>
          <template v-else>
            <div class="flex items-start w-full py-2">
              <div v-if="item.fromUser.user_id != imUserId">
                <!-- :style="{
                width: isMobile ? '2.1875rem' : '2.8125rem',
                height: isMobile ? '2.1875rem' : '2.8125rem',
                }" -->
                <div style="overflow: hidden;width: 2.8125rem;height: 2.8125rem;" class="mr-2 rounded-md">
                  <img
                    :src="item.fromUser.avatar ? item.fromUser.avatar : '../../../../../assets/images/chat/avatar.png'"
                    alt="" style="width:100%;height:100%;object-fit:cover" @error="imgError(item, false)" />
                </div>
                <div style="display: inline-block;padding: .125rem .375rem;"
                  class="mt-2 mr-2 text-xs text-center text-white rounded-sm bg-primary"
                  v-if="isShowFlag(item.fromUser.user_id)">客服</div>
              </div>
              <template>
                <image-message :isMine="item.fromUser.user_id == imUserId ? true : false" :message="item"
                  v-if="item.type == 'image'" @previewImage="previewImage"></image-message>
                <text-message :isMine="item.fromUser.user_id == imUserId ? true : false" :message="item"
                  v-if="item.type == 'text'"></text-message>
                <video-message :isMine="item.fromUser.user_id == imUserId ? true : false" :message="item"
                  v-if="item.type == 'video'"></video-message>
                <order-message :isMine="item.fromUser.user_id == imUserId ? true : false" :message="item"
                  v-if="item.type == 'order'"></order-message>
              </template>
              <!-- :style="{
              width: isMobile ? '2.1875rem' : '2.8125rem',
              height: isMobile ? '2.1875rem' : '2.8125rem',
              }" -->
              <div style="overflow: hidden;width: 2.8125rem;height: 2.8125rem;" class="ml-2 rounded-md"
                v-if="item.fromUser.user_id == imUserId">

                <img :src="item.fromUser.avatar ? item.fromUser.avatar : '../../../../../assets/images/chat/avatar.png'"
                  alt="" style="width:100%;height:100%;object-fit:cover" @error="imgError(item, true)" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <Popup :isShow="isShow" @close="closePreview" :width="isMobile ? '90vw' : '500px'">
      <template>
        <div class="p-4">
          <img :src="previewImageUrl" alt="" style="width: 100%;" />
        </div>
      </template>
    </Popup>
  </div>
</template>
<script>
import ImageMessage from "./messageElement/ImageMessage.vue";
import OrderMessage from "./messageElement/OrderMessage.vue";
import TextMessage from "./messageElement/TextMessage.vue";
import VideoMessage from "./messageElement/VideoMessage.vue";
import SystemMessage from "./messageElement/SystemMessage.vue";
import Popup from "@/components/Popup/Popup";
import { mapState } from "vuex";
export default {
  props: {
    messageHeight: {
      type: Number,
      default: 200,
    },
  },
  components: {
    ImageMessage,
    OrderMessage,
    TextMessage,
    VideoMessage,
    SystemMessage,
    Popup,
  },
  data() {
    return {
      tipInfo: "点击加载更多...",
      isRefresh: false, // 是否处于刷新状态
      initScrollHeight: 0, // 初始滚动高度
      isShow: false, // 图片预览
      previewImageUrl: "", // 图片预览地址
      avatar: require("../../../../../assets/images/chat/avatar.png"),
    };
  },
  computed: {
    ...mapState(["isMobile", "userInfo"]),
    ...mapState("chat", ["message", "imUserId", "serviceList"]),
  },
  mounted() {
    this.scrollToButtom();
    this.$EventBus.$on("scrollToButtom", () => {
      this.$nextTick(() => {
        this.scrollToButtom();
      });
    });
  },
  methods: {
    /**
     * @description: 是否展示客服标识
     * @param {*} id 用户的imId
     * @return {*}
     */
    isShowFlag(id) {
      return this.serviceList.findIndex(item => id == item.user_id) > -1
    },

    /**
     * @Descripttion: 消息区域滚动到底部
     * @return {*}
     */
    scrollToButtom() {
      var container = this.$el.querySelector("#messageEle");
      this.$nextTick(() => {
        if (this.isRefresh) {
          // console.log(container.scrollHeight, this.initScrollHeight);
          this.isRefresh = false;
          container.scrollTop = container.scrollHeight - this.initScrollHeight;
          return;
        }
        setTimeout(() => {
          container.scrollTop = container.scrollHeight;
        }, 400);
      });
    },
    /**
     * @description: 消息加载更多
     * @return {*}
     */
    loadmore() {
      this.isRefresh = true;
      var container = this.$el.querySelector("#messageEle");
      this.initScrollHeight = container.scrollHeight;
      // this.getMessageList();
      this.$store.dispatch("chat/getMessageList", { type: "loadmore" });
    },
    /**
     * @description: 图片预览
     * @param {string} url 路径
     * @return {*}
     */
    previewImage(url) {
      this.isShow = true;
      this.previewImageUrl = url;
    },
    /**
     * @description: 关闭预览
     * @return {*}
     */
    closePreview() {
      this.isShow = false;
    },
    /**
     * @description: 处理图片加载失败
     * @param {object} user 用户
     * @param {boolean} flag 是否是自己的标识
     * @return {*}
     */
    imgError(user, flag) {
      user.fromUser.avatar = this.avatar
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
