<!--
 * @Author: 李金深 2896583081@qq.com
 * @Date: 2023-02-06 09:07:28
 * @LastEditors: 李金深 2896583081@qq.com
 * @LastEditTime: 2023-03-07 18:03:33
 * @FilePath: /com.huizhouyiren.B0805/src/views/index/pages/sales/chat.vue
 * @Description: 聊天主页
-->
<template>
  <div class="pt-4 equipment_box">
    <navbar-text :navbarList="navbarList"></navbar-text>
    <!-- <div class="px-2 text-left text-gray-500 path">首页-售后服务-在线客服</div> -->
    <div class="flex justify-between" :style="{ height: chatHeight + 'px' }">
      <div class="h-full bg-gray-100" style="width:21.875rem;overflow-y: scroll;" v-if="isServer && !isMobile">
        <chat-list @viewOrder="viewOrder"></chat-list>
      </div>
      <div class="flex-1 chat_box">
        <div class="flex items-center justify-between px-3 bg-gray-100" :style="{ height: headerHeight + 'px' }"
          style="overflow:hidden" v-if="isMobile">
          <template v-if="userInfo.isService">
            <div>
              <div class="text-left">ID：{{ currentSessionUser.account }}</div>
              <div class="text-left">
                手机号：{{ currentSessionUser.mobile }}
              </div>
            </div>
          </template>
        <template v-else>
            <div>
              <div class="font-bold text-left">客服</div>
            </div>
          </template>
          <div class="flex items-center">
            <div v-if="!isServer">
              <!-- <img src="../../../../assets/images/chat/order.png" alt="" style="width: 1.6875rem"
                                              @click="orderDrawer = true" /> -->
              <span class="text-sm text-primary" @click="orderDrawer = true">工单列表</span>
            </div>
            <div class="flex items-center" @click="drawer = true" v-if="userInfo.isService" style="margin-left: .625rem;">
              <div v-if="unreadFlag" class="text-xs text-white rounded bg-primary"
                style="top:-10px;right:-10px;padding:2px 6px">
                news
              </div>
              <img src="../../../../assets/images/chat/chat.png" alt="" style="width: 1.625rem;" />
            </div>
          </div>
        </div>
        <message-list :messageHeight="messageHeight"></message-list>
        <message-input :inputHeight="inputHeight"></message-input>
      </div>
      <div class="p-4 bg-gray-100" style="width:21.875rem;" v-if="!isServer && !isMobile">
        <order-info :orderList="orderList" :fromUser="{
          avatar: userInfo.userPicture,
          account: userInfo.userId,
          mobile: userInfo.telphone,
        }"></order-info>
      </div>
    </div>
    <el-drawer title="聊天列表" :visible.sync="drawer" :direction="direction" :before-close="handleClose" size="85vw">
      <div style="width: 85vw;height: 100%;overflow-y: scroll;" class="px-3 pb-2">
        <chat-list @viewOrder="viewOrder"></chat-list>
      </div>
    </el-drawer>
    <el-drawer title="工单列表" :visible.sync="orderDrawer" direction="btt" :before-close="handleOrderClose" size="90vh">
      <div style="width: 98vw;height: 100%;overflow-y: scroll;" class="px-3 pb-2">
        <order-info :orderList="orderList" :fromUser="{
          avatar: userInfo.userPicture,
          account: userInfo.userId,
          mobile: userInfo.telphone,
        }" @closeFunc="emitClose">
        </order-info>
      </div>
    </el-drawer>
    <Popup :isShow="isShow" @close="closeFunc">
      <template>
        <div class="p-4" style="max-height:80vh;overflow-y: scroll;">
          <order-info :orderList="orderList" :fromUser="fromUser" @closeFunc="emitClose"></order-info>
        </div>
      </template>
    </Popup>
  </div>
</template>

<script>
import MessageInput from "./components/MessageInput.vue";
import MessageList from "./components/MessageList.vue";
import ChatList from "./components/ChatList.vue";
import OrderInfo from "./components/OrderInfo.vue";
import Popup from "@/components/Popup/Popup";
import { getScreenHeight } from "@/utils/utils.js";
import { mapState } from "vuex";
export default {
  data() {
    return {
      screenHeight: 0, // 屏幕工作区域的高度和宽度（去掉状态栏）
      chatHeight: 0, // 聊天窗口的高度
      messageHeight: 0, // 消息窗口的高度
      inputHeight: 200, // 底部输入框的高度
      headerHeight: 60, // 头部信息高度
      drawer: false, // 聊天列表弹窗标识
      direction: "ltr",
      isShow: false, // 订单弹窗web端
      orderDrawer: false, // 订单弹窗移动端
      orderList: [], // 工单列表
      fromUser: {
        avatar: "",
        mobile: "",
        account: "",
      },
      navbarList: [{ title: "售后服务", path: "" }, { title: '在线客服', path: '' }]
    };
  },
  components: {
    MessageInput,
    MessageList,
    ChatList,
    OrderInfo,
    Popup,
  },
  created() {
    this.calculationHeight();
    this.$store.dispatch("getAccessToken", this.$EventBus)// 获取七牛云授权token
    this.$store.dispatch("order/getRepairFrom")
    window.addEventListener(
      "resize",
      this.debounce(this.calculationHeight, 500)
    );
    if (this.userInfo.isService) {
      this.$store.dispatch("chat/getChatList", {
        page: 1,
        callback: (list) => {
          // console.log("list", list);
          if (list.length > 0) {
            setTimeout(() => {
              this.$store.dispatch("chat/getMessageList", {
                type: "init",
                callback: () => { },
              });
            }, 50);
          }
        },
      });
      this.$store.dispatch("chat/getQuickReply");
    } else {
      this.getOrderList();
      if (this.currentSessionUser.user_id) {
        this.initData();
      }
    }
  },
  mounted() {
    window.$EventBus.$emit("clearTitleNotify")
  },
  watch: {
    currentSessionUser: {
      handler: function () {
        if (!this.userInfo.isService) {
          if (this.currentSessionUser.user_id) {
            this.initData();
          }
        }
      },
    },
    deep: true,
    immediate: true,
  },
  computed: {
    ...mapState(["isMobile", "userInfo"]),
    ...mapState('chat', ['unreadFlag']),
    ...mapState({
      isServer: (state) => state.userInfo.isService,
      currentSessionUser: (state) => state.chat.currentSessionUser,
    }),
  },
  methods: {
    emitClose() {
      this.isShow = false
      this.orderDrawer = false
    },

    // 获取用户自己的工单
    getOrderList() {
      this.$request({
        method: "get",
        url: this.$requestPath.equipment,
        data: {},
      })
        .then((res) => {
          if (res.code == 0) {
            this.orderInfo = [];
            this.orderList.push(...res.data);
          } else {
            this.$EventBus.$emit("toast", { type: "error", message: res.msg });
          }
        })
        .catch((error) => {
          console.log("获取用户设备", error);
        });
    },
    // 查看用户工单
    viewOrder(item) {
      this.drawer = false
      // console.log("查看工单", item);
      Object.keys(this.fromUser).map((key) => {
        this.fromUser[key] = item[key];
      });
      // console.log(this.fromUser);
      this.$request({
        url: this.$requestPath.equipmentList,
        methods: "get",
        params: {
          userId: item.account,
        },
      }).then((res) => {
        // console.log("获取的工单列表", res);
        if (res.code == 0) {
          this.orderInfo = [];
          this.orderList.push(...res.data);
          this.isShow = true;
        } else {
          this.$EventBus.$emit("toast", { type: "error", message: res.msg });
        }
      });
    },
    initData() {
      this.$store.dispatch("chat/getMessageList", {
        type: "init",
        callback: () => { },
      });
    },
    // 计算高度
    calculationHeight() {
      this.screenHeight = getScreenHeight();
      // let isMobile = localStorage.getItem("isMobile") == "true" ? true : false;
      let navbarHeight = this.isMobile ? 40 : 70;
      this.inputHeight = this.isMobile ? 110 : 180;
      this.headerHeight = this.isMobile ? 50 : 0;
      this.chatHeight = this.screenHeight - navbarHeight - 50;
      this.messageHeight =
        this.chatHeight - this.inputHeight - this.headerHeight - 10;
    },
    debounce(fn, wait) {
      var timer = null;
      return function () {
        if (timer !== null) {
          clearTimeout(timer);
        }
        timer = setTimeout(fn, wait);
      };
    },
    handleClose() {
      this.drawer = false;
    },
    handleOrderClose() {
      this.orderDrawer = false;
    },
    closeFunc() {
      this.isShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 640px) {
  .chat_box {
    width: 100%;
  }
}

@media screen and (min-width: 640px) {
  .chat_box {
    border: 1px solid #000;
    box-sizing: border-box;
  }

  .equipment_box {
    margin: 0 var(--margin-base);
    //   min-height: var(--min-height);
  }
}
</style>
<style></style>
