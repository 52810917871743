<!--
 * @Author: 李金深 2896583081@qq.com
 * @Date: 2023-02-06 09:07:28
 * @LastEditors: 李金深 2896583081@qq.com
 * @LastEditTime: 2023-02-10 11:16:55
 * @FilePath: /com.huizhouyiren.b0805/src/views/index/pages/sales/components/ChatList.vue
 * @Description: 会话列表
-->
<template>
  <div class="w-full">
    <template v-if="chatList.length > 0">
      <div class="flex items-center justify-between px-4 py-2 border-b border-gray-200 border-solid"
        v-for="item in chatList" :key="item.id" :class="currentSessionUser.account == item.account ? 'bg-red-100' : ''">
        <div class="flex items-center w-full">
          <div style="overflow: hidden;" class="rounded-md cursor-pointer" :style="{
            width: isMobile ? '2.8125rem' : '3.125rem',
            height: isMobile ? '2.8125rem' : '3.125rem',
          }" @click="changeChat(item)">
            <img :src="item.avatar" alt="" style="width:100%;height:100%;object-fit:cover" @error="imgError(item)" />
          </div>
          <div class="flex-1 pl-2">
            <div class="flex items-start justify-between">
              <div class="cursor" @click="changeChat(item)">
                <div class="text-left">
                  {{ item.realname }}
                </div>
                <div class="text-left">ID:{{ item.account }}</div>
              </div>
              <div style="padding:0px 4px;" class="text-xs text-white rounded bg-primary" v-if="item.unread">
                news
              </div>
            </div>
            <div class="flex items-center justify-between w-full">
              <div class="text-left" @click="changeChat(item)">
                手机号：{{ item.mobile }}
              </div>
              <div class="text-xs border border-solid rounded cursor-pointer text-primary border-primary cursor-pointers"
                style="padding: 0.125rem 0.25rem;user-select: none;" @click="viewOrder(item)">
                查看工单
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="py-3 text-gray-500">
      暂无联系人
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      avatar: require("../../../../../assets/images/chat/avatar.png"),
    };
  },
  computed: {
    ...mapState(["isMobile"]),
    ...mapState("chat", ["chatList", "currentSessionUser"]),
  },
  methods: {
    // switchContacts(item) {
    //   this.$store.commit("chat/updateCurrentSessionUser", item);
    //   this.$store.dispatch("chat/getMessageList", {
    //     type: "init",
    //     callback: () => {},
    //   });
    // },
    /**
     * @Descripttion: 查看工单
     * @param {object} user 用户
     * @return {*}
     */

    viewOrder(user) {
      this.$emit("viewOrder", user);
    },
    /**
     * @Descripttion: 切换聊天
     * @param {object} user 用户
     * @return {*}
     */

    changeChat(user) {
      this.$store.commit("chat/updateCurrentSessionUser", user);
      this.$store.dispatch("chat/getMessageList", {
        type: "init",
        callback: () => { },
      });
    },
    /**
     * @description: 处理图片加载失败
     * @param {*} user
     * @return {*}
     */
    imgError(user) {
      user.avatar = this.avatar;
    },
  },
};
</script>
<style lang="scss" scoped></style>
